import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Link } from 'react-router-dom';

import { SanityContentLibrary } from '@guider-global/shared-types';

import { buildSanityImageUrl } from '@guider-global/sanity-hooks';
import { getContentTypeIcon } from 'utils/getContentTypeIcon';
import { useMixpanelEvents, useMobileMediaQuery } from 'hooks';

interface Props extends SanityContentLibrary {
  variant: 'horizontal' | 'vertical';
}

const getTypeLabel = ({ type }: { type: string }) => {
  if (type === 'pdf') {
    return 'PDF';
  } else {
    return type.charAt(0).toUpperCase() + type.slice(1);
  }
};

const Tile: React.FC<Props> = ({ content, metadata, variant }) => {
  const { trackScreenTimeAndClick } = useMixpanelEvents();
  const { image } = content;
  const {
    type,
    slug: { current: contentSlug },
  } = metadata;

  const excerpt = content?.excerpt ? content?.excerpt : '';
  const title = content?.title || '';

  const isMobile = useMobileMediaQuery();

  const isVertical = variant === 'vertical';
  const isHorizontal = variant === 'horizontal';

  const isLink = type === 'link';

  const videoDuration = content?.video_duration;
  const isLongDuration = videoDuration && parseInt(videoDuration) > 5;

  return (
    <Card
      onClick={() =>
        trackScreenTimeAndClick('Content piece - Clicked', {
          'Content type': type,
        })
      }
      sx={{
        margin: 1,
        height: '100%',
      }}
    >
      <CardActionArea
        sx={{
          width: '100%',
          height: '100%',
          position: 'relative',
          ...(isHorizontal && {
            display: 'flex',
            justifyContent: 'flex-start',
          }),
        }}
        {...(isLink && {
          href: content.link ? content.link : '',
          target: '_blank',
          rel: 'noopener noreferrer',
        })}
        {...(!isLink && { component: Link, to: `${contentSlug}` })}
      >
        {image && (
          <CardMedia
            component="img"
            src={buildSanityImageUrl({ source: image, width: 800 })}
            {...(isVertical && { height: 176 })}
            {...(isHorizontal && {
              sx: { width: isMobile ? '50%' : '211px', height: '100%' },
            })}
          />
        )}
        {image && videoDuration && (
          <Typography
            variant={'caption'}
            component={'p'}
            sx={{
              position: 'absolute',
              zIndex: 10,
              backgroundColor: 'black',
              color: 'white',
              p: '4px',
              borderRadius: '3px',
              fontWeight: 500,
              ...(isVertical && { right: '10px', top: '140px' }),
              ...(isHorizontal && {
                left: isMobile ? '40%' : isLongDuration ? '154px' : '160px',
                bottom: '10px',
              }),
              ':dir(rtl)': {
                ...(isVertical && {
                  right: 'auto',
                  left: '10px',
                  top: '140px',
                }),
                ...(isHorizontal && {
                  left: 'auto',
                  right: isMobile ? '40%' : isLongDuration ? '154px' : '160px',
                  bottom: '10px',
                }),
              },
            }}
          >
            {videoDuration}
          </Typography>
        )}
        <CardContent>
          <Box
            sx={{
              color: 'text.disabled',
              gap: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {getContentTypeIcon({ type })}
            <Typography variant="caption">{getTypeLabel({ type })}</Typography>
          </Box>
          <Typography variant="h6" component="h2" sx={{ fontSize: '18px' }}>
            {title}
          </Typography>
          <Typography
            variant="caption"
            component="p"
            sx={{
              color: 'text.secondary',
              lineHeight: '20px',
              letterSpacing: '0.4px',
              fontWeight: 500,
            }}
          >
            {excerpt}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default Tile;
