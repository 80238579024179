import React, { useMemo, useState } from 'react';
// internal
import { UserIntegrationName } from '@guider-global/shared-types';

import { useLocalization, useUserIntegrations } from 'hooks';

//store

// components
import { useUsers } from '@guider-global/front-end-hooks';
import { getSubDomain } from '@guider-global/front-end-utils';
import {
  useBaseLanguage,
  useOrganization,
  useSettings,
} from '@guider-global/sanity-hooks';
import { Box } from '@mui/material';
import { getImageSource } from 'utils/getImageSource';
import { IntegrationsCard, IntegrationsCardProps } from './IntegrationsCard';

interface IntegrationCardList extends IntegrationsCardProps {
  key: string;
}

export const IntegrationsList: React.FC = () => {
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {},
  );

  const toggleLoadingState = (integrationName: string, isLoading: boolean) => {
    setLoadingStates((prevStates) => ({
      ...prevStates,
      [integrationName]: isLoading,
    }));
  };

  const { settings } = useSettings({ localeCode: 'en_GB' });

  const msTeamImageSrc = getImageSource({
    source: settings?.static_media?.integrations?.active_directory_teams,
  });

  const outlookCalendarImageSrc = getImageSource({
    source:
      settings?.static_media?.integrations?.active_directory_outlook_calendar,
  });
  // Subdomain
  const organizationSlug = getSubDomain();
  const { localeCode } = useLocalization(organizationSlug);

  // - Users
  const { users } = useUsers({});
  const user = users?.at(0);

  // - Integrations
  const { userIntegrations, reqUserIntegrations } = useUserIntegrations({
    waitForAuthentication: true,
  });

  const ADOutlookIntegration = useMemo(
    () =>
      userIntegrations?.find(
        (userIntegration) =>
          userIntegration.integrationName ===
          'active-directory-outlook-calendar',
      ),
    [userIntegrations],
  );

  const ADTeamsIntegration = useMemo(
    () =>
      userIntegrations?.find(
        (userIntegration) =>
          userIntegration.integrationName === 'active-directory-teams',
      ),
    [userIntegrations],
  );

  // - Base language
  const { baseLanguage } = useBaseLanguage({ localeCode });

  const integrations = baseLanguage?.settings?.integrations;
  const outlookCalendarLabel =
    integrations?.active_directory_outlook_calendar_label;
  const outlookCalendarDescription =
    integrations?.active_directory_outlook_calendar_description;
  const outlookTeamsLabel = integrations?.active_directory_outlook_teams_label;
  const outlookTeamsDescription =
    integrations?.active_directory_outlook_teams_description;

  // - Organization

  const { organization } = useOrganization({
    organizationSlug: organizationSlug,
  });
  const enabledIntegrations = organization?.white_label?.integrations;
  const calendar = enabledIntegrations?.calendar;
  const videoConferencing = enabledIntegrations?.video_conferencing;

  // Events
  const handleDisconnectIntegration = async (integrationName: string) => {
    toggleLoadingState(integrationName, true);

    const integrationIdToDisconnect = userIntegrations?.find(
      (userIntegration) => userIntegration.integrationName === integrationName,
    )?.integrationName;

    await reqUserIntegrations({
      method: 'DELETE',
      url: `/userIntegrations/${integrationIdToDisconnect}`,
    });

    await reqUserIntegrations({ url: '/userIntegrations' });
    toggleLoadingState(integrationName, false);
  };

  const handleConnectIntegration = async (
    integrationName: UserIntegrationName,
  ): Promise<void> => {
    try {
      toggleLoadingState(integrationName, true);

      await reqUserIntegrations({
        method: 'PUT',
        url: `/userIntegrations/${integrationName}`,
        data: {
          integrationName,
          features:
            integrationName === 'active-directory-outlook-calendar'
              ? ['sessions:availabilities:retrieve']
              : integrationName === 'active-directory-teams'
              ? ['sessions:videoConferencing:create']
              : [],
        },
      });

      await reqUserIntegrations({ url: '/userIntegrations' });
    } catch (error) {
      console.error('Error connecting integration:', error);
    } finally {
      toggleLoadingState(integrationName, false);
    }
  };

  const cardTypes: IntegrationCardList[] = [
    {
      key: 'active-directory-outlook-calendar',
      iconSource: outlookCalendarImageSrc,
      header: outlookCalendarLabel,
      subheader: ADOutlookIntegration
        ? user?.email || ''
        : outlookCalendarDescription,
      connected: !!ADOutlookIntegration,
      onClickDisconnect: async () =>
        await handleDisconnectIntegration('active-directory-outlook-calendar'),
      onClickConnect: async () =>
        await handleConnectIntegration('active-directory-outlook-calendar'),
      enabled: calendar?.active_directory_outlook_calendar ?? false,
      loading: loadingStates['active-directory-outlook-calendar'] ?? false,
    },
    {
      key: 'active-directory-teams',
      iconSource: msTeamImageSrc,
      header: outlookTeamsLabel,
      subheader: ADTeamsIntegration
        ? user?.email || ''
        : outlookTeamsDescription,
      connected: !!ADTeamsIntegration,
      onClickDisconnect: async () =>
        await handleDisconnectIntegration('active-directory-teams'),
      onClickConnect: async () =>
        await handleConnectIntegration('active-directory-teams'),
      enabled: videoConferencing?.active_directory_teams ?? false,
      loading: loadingStates['active-directory-teams'] ?? false,
    },
  ];
  return (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', gap: 3 }}>
      {cardTypes.map((card) => (
        <IntegrationsCard
          key={card.key}
          iconSource={card.iconSource}
          header={card.header}
          subheader={card.subheader}
          connected={card.connected}
          onClickDisconnect={card.onClickDisconnect}
          onClickConnect={card.onClickConnect}
          enabled={card.enabled}
          loading={card.loading}
        />
      ))}
    </Box>
  );
};
export { IntegrationsCard } from './IntegrationsCard';
