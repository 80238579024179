/* eslint-disable camelcase */

import {
  Box,
  Button,
  Grid,
  SxProps,
  Theme,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';

import { SanityLearningHubTileGroup } from '@guider-global/shared-types';
import { useMobileMediaQuery } from 'hooks';

import Tile from '../Tile';

import 'swiper/css';
import 'swiper/css/pagination';

import './styles.css';

type Props = SanityLearningHubTileGroup;

const TileGroup: React.FC<Props> = ({
  tile_group_content_group,
  tile_group_length,
  tile_group_style,
  tile_group_subtitle,
  tile_group_title,
}) => {
  const theme = useTheme();
  const isMobile = useMobileMediaQuery();
  const isSmallDesktop = useMediaQuery(theme.breakpoints.down('xl'));
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);

  const buttonStyles: SxProps<Theme> = {
    borderRadius: '18px',
    height: '36px',
    width: '36px',
    minWidth: 0,
    padding: 0,
    mr: 1,
  };

  const TileGroupNavigation = () => {
    const swiper = useSwiper();

    swiper.on('slideChange', function () {
      setIsEnd(swiper.isEnd);
      setIsBeginning(swiper.isBeginning);
    });

    return (
      <Grid
        container
        columns={12}
        sx={{
          my: 1,
          px: 1,
          flexDirection: 'row',
        }}
      >
        <Grid item xs></Grid>
        <Grid item xs={'auto'}>
          <Button
            data-cy="components_LearningHub_TileGroup_slide-prev-button"
            onClick={() => swiper.slidePrev()}
            variant="outlined"
            disabled={isBeginning ? true : false}
            sx={{
              ...buttonStyles,
              borderColor: 'info.dark',
              color: 'info.dark',
              transition:
                'border-color linear 0.2s, background-color linear 0.2s',
              '&:hover': {
                backgroundColor: `${theme.palette.info.main}14`,
                borderColor: `${theme.palette.info.main}00`,
                transition:
                  'border-color linear 0.2s, background-color linear 0.2s',
              },
            }}
          >
            <MdArrowBack fontSize="large" />
          </Button>
          <Button
            data-cy="components_LearningHub_TileGroup_slide-next-button"
            onClick={() => swiper.slideNext()}
            variant="contained"
            disabled={isEnd}
            sx={{
              ...buttonStyles,
              backgroundColor: 'info.dark',
              transition: 'opacity linear 0.2s',
              '&:hover': {
                backgroundColor: 'info.main',
                opacity: '70%',
                transition:
                  'opacity linear 0.2s, background-color ease-in-out 0.1s',
              },
            }}
            disableElevation
          >
            <MdArrowForward fontSize="large" />
          </Button>
        </Grid>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        width: isMobile ? '95%' : '83vw',
        maxWidth: '100%',
        mx: 'auto',
        my: 2,
        direction: 'ltr',
      }}
    >
      <Box sx={{ m: 1 }}>
        <Typography variant="h5" component="h2">
          {tile_group_title}
        </Typography>
        <Typography variant="body2" component="h3" color="text.secondary">
          {tile_group_subtitle}
        </Typography>
      </Box>

      <Swiper
        slidesPerView={isMobile ? 1.5 : tile_group_length}
        centeredSlides={isMobile}
        centeredSlidesBounds={isMobile}
        pagination={{
          clickable: true,
          enabled: !isMobile,
          renderBullet: () =>
            `<span class="swiper-pagination-bullet pagination-pill"></span>`,
        }}
        modules={[Pagination]}
        style={{
          ['--swiper-theme-color' as string]: theme.palette.info.main,
        }}
      >
        {tile_group_content_group.content.content_references.map((content) => {
          return (
            <SwiperSlide
              key={content.metadata.slug.current}
              style={{ height: 'unset', paddingBottom: '20px' }}
            >
              <Tile
                variant={
                  (tile_group_length >= 4 && isSmallDesktop) || isMobile
                    ? 'vertical'
                    : tile_group_style
                }
                {...content}
              />
            </SwiperSlide>
          );
        })}
        {!isMobile && <TileGroupNavigation />}
      </Swiper>
    </Box>
  );
};

export default TileGroup;
